export const musteatTitle = (data) => {
  const {
    translate_commercial_area_location_name1: loc1,
    translate_commercial_area_location_name2: loc2,
    translate_commercial_area_location_name3: loc3,
    translate_commercial_area_name: area_name,
    translate_must_eat_name: must_eat_name,
  } = data

  const isEmpty = (value) => {
    if (!value || value === '') return null
    return value
  }

  return `${loc1} ${isEmpty(loc2) ? `> ${loc2}` : ''} ${
    isEmpty(loc3) ? `> ${loc3}` : ''
  } ${isEmpty(area_name) ? `> ${area_name}` : ''} ${
    isEmpty(must_eat_name) ? `> ${must_eat_name}` : ''
  }`
}
