import { useEffect } from 'react'
import TagManager from 'react-gtm-module'

export default function GoogleTagManager() {
  const tagManagerArgs = {
    gtmId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_GTMID,
  }

  useEffect(() => {
    TagManager.initialize(tagManagerArgs)
  }, [])

  return <></>
}
