import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'

const ErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = useState(false)

  const resetError = () => {
    setHasError(false)
  }

  // 에러 감지하는 useEffect
  useEffect(() => {
    const handleError = (error, errorInfo) => {
      console.log({ error, errorInfo })
      setHasError(true)
    }

    // 에러 리스너 추가
    window.addEventListener('error', handleError)
    window.addEventListener('unhandledrejection', handleError)

    // cleanup 함수에서 리스너 제거
    return () => {
      window.removeEventListener('error', handleError)
      window.removeEventListener('unhandledrejection', handleError)
    }
  }, [])

  if (hasError) {
    return (
      <Container>
        <div>
          <Status>oops</Status>
          <Message>There is an error</Message>
          <GoToHome onClick={resetError}>Try again</GoToHome>
        </div>
      </Container>
    )
  }

  return children
}

export default ErrorBoundary

const Container = styled.main`
  width: 100%;
  height: 100vh;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Status = styled.h1`
  font-size: 100px;
  font-weight: 200;

  background: linear-gradient(
    135deg,
    rgba(255, 74, 82, 1) 0%,
    rgba(255, 115, 61, 0.3) 50%,
    rgba(255, 74, 82, 1) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
`

const Message = styled.p`
  font-size: 20px;
  text-align: center;

  background: linear-gradient(
    135deg,
    rgba(255, 74, 82, 1) 0%,
    rgba(255, 115, 61, 0.3) 50%,
    rgba(255, 74, 82, 1) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
`

const GoToHome = styled(Message)`
  font-size: 12px;
  margin-top: 50px;
  text-decoration: underline;
  cursor: pointer;
`
