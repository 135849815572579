import React, { useEffect } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'

import { Cookies } from 'react-cookie'
import { useDispatch } from 'react-redux'
import wrapper from 'store/index'
import { appWithTranslation, useTranslation } from 'next-i18next'

import {
  getFrontCart,
  getFrontUserBookmark,
  getFrontUserProfile,
  postFrontUserTokencheck,
} from 'api'
// import { setGps } from 'store/gps'
import { setAuth } from 'store/auth'
import { setCart } from 'store/cart'
import { setWishlist } from 'store/wishlist'
import { Global } from '@emotion/react'
import global from 'style/globalStyle'
import ErrorBoundary from 'components/ErrorBoundary'

import { GoogleOAuthProvider } from '@react-oauth/google'
import { DEFAULT_LANG } from 'constants/lang'
import {
  generateMD5Hash,
  switchChannel,
  switchLocale,
  switchWhitelabel,
} from 'lib'
import { setCookie } from 'cookies-next'
import GoogleTagManager from 'components/GoogleTagManager'

const App = ({ Component, pageProps }) => {
  const router = useRouter()
  const dispatch = useDispatch()
  const cookies = new Cookies()

  const lang = cookies.get('lang')
  const token = cookies.get('token')
  const user_id = cookies.get('user_id')

  const { i18n } = useTranslation()
  const { languages } = i18n

  useEffect(() => {
    history.scrollRestoration = 'manual'

    // 세션 체크 로직
    if (token) {
      cookies.remove('not_login_user_id')

      postFrontUserTokencheck(user_id, token)
        .then((check) => {
          if (check === true) {
            // 유저 id와 토큰값 dispatch (HOC 검증용)
            getFrontUserProfile(token).then((res) => {
              if (res.status === 'success') {
                dispatch(setAuth({ token, id: res.data.id }))
              }
            })

            // 위시리스트 정보 dispatch
            let arr = []
            getFrontUserBookmark({ token, s_lang: lang }).then((res) => {
              res.data.map((item) =>
                arr.push(item.product_id ? item.product_id : item.store_id)
              )
              dispatch(setWishlist({ wishlist: arr }))

              // 장바구니 정보 dispatch
              callGetFrontCart()
            })
          } else {
            cookies.remove('token')
            cookies.remove('user_id')
          }
        })
        .catch(() => {
          cookies.remove('token')
          cookies.remove('user_id')
        })
    } else {
      if (!cookies.get('not_login_user_id'))
        cookies.set('not_login_user_id', generateMD5Hash(), {
          path: '/',
          expires: new Date(2999, 12, 31),
        })
    }

    // ---------- language : i18n ----------
    // locale에 없는 언어가 들어오면 404로 리턴
    if (!languages) return
    const locale = switchLocale(window.location.pathname.split('/')[1])

    // RWG 관련 패스에선 i18n 라우팅 제외
    if (window.location.pathname.includes('rwg')) return

    if (lang === 'undefined') {
      return cookies.set('lang', DEFAULT_LANG, {
        path: '/',
        expires: new Date(2999, 12, 31),
      })
    }

    if (locale === '') {
      if (lang) {
        router.push(router.asPath, router.asPath, {
          locale: lang,
          scroll: false,
        })
      } else {
        cookies.set('lang', DEFAULT_LANG, {
          path: '/',
          expires: new Date(2999, 12, 31),
        })
        router.push(router.asPath, router.asPath, {
          locale: DEFAULT_LANG,
          scroll: false,
        })
      }
    } else {
      cookies.set('lang', locale, {
        path: '/',
        expires: new Date(2999, 12, 31),
      })
      router.push(router.asPath, router.asPath, {
        locale: locale,
        scroll: false,
      })
    }

    // GPS 세팅
    // navigator.geolocation.getCurrentPosition(position => {
    //   const lat = position.coords.latitude;
    //   const lng = position.coords.longitude;
    //   dispatch(setGps({ lat, lng }))
    // });
  }, [])

  const callGetFrontCart = async () => {
    await getFrontCart({ token }).then((res) => {
      let count = 0
      res.data.map((item) => (count += item.quantity))

      dispatch(
        setCart({
          count,
          store: res.store?.id ?? null,
          is_package:
            res.data.length > 0 ? res.data[0].is_package === 1 : false,
        })
      )
    })
  }

  return (
    <>
      <Global styles={global} />
      <Head>
        <link rel="icon" href="/favicon.png" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />

        <title>{switchWhitelabel(pageProps.channel).title ?? 'REDTABLE'}</title>
      </Head>
      <ErrorBoundary>
        <GoogleOAuthProvider
          clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}
        >
          <GoogleTagManager />
          <Component {...pageProps} />
        </GoogleOAuthProvider>
      </ErrorBoundary>
    </>
  )
}

App.getInitialProps = async ({ Component, ctx }) => {
  let pageProps = {}

  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx)
  }

  const isServer = typeof window === 'undefined'
  let subdomain

  if (isServer) {
    subdomain =
      process.env.NODE_ENV === 'development'
        ? process.env.NEXT_PUBLIC_SUBDOMAIN
        : ctx.req
        ? ctx.req.headers.host.split('.')[0]
        : 'redtable'
  } else {
    subdomain =
      process.env.NODE_ENV === 'development'
        ? process.env.NEXT_PUBLIC_SUBDOMAIN
        : window.location.hostname.split('.')[0]
  }

  setCookie('subdomain', subdomain, { req: ctx.req, res: ctx.res })

  const channel = switchChannel(subdomain)
  setCookie('channel', channel, { req: ctx.req, res: ctx.res })

  pageProps = { ...pageProps, channel }
  return {
    pageProps,
  }
}

export default wrapper.withRedux(appWithTranslation(App))
