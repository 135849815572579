export const DEFAULT_LANG = 'en'

export const languages = [
  {
    key: 'ko',
    label: '한국어',
  },
  {
    key: 'en',
    label: 'English',
  },
  {
    key: 'ja',
    label: '日本語',
  },
  {
    key: 'zh-Hans',
    label: '简体中文',
  },
  // {
  //   key: 'es',
  //   label: 'Español',
  // },
]

export const rwgLanguages = [
  {
    key: 'ko',
    label: '한국어',
  },
  {
    key: 'en',
    label: 'English',
  },
  {
    key: 'ja',
    label: '日本語',
  },
  {
    key: 'zh-Hans',
    label: '中文(简体)',
  },
  {
    key: 'zh-Hant',
    label: '中文(繁體)',
  },
]
